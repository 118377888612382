import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const earthSeedsPage = ({ location }) => {
  return (
    <Layout location={location} title="EarthSeeds Project">
      <SEO title="EarthSeeds Project" />
      <section id="earthseeds-page" className="page">
        <div className="container text-white">
          <div className="content bg-black p-5">
            <p>
              The EarthSeeds Project is powered by an international community of
              people using Astronaut views of Earth to inspire and educate
              adults and children however they can. EarthSeeds are the authentic
              Earth Images themselves and the unique effect they have on you as
              an individual when you experience them.
            </p>
            <p>
              These moments of reflection or purposeful contemplation are
              naturally occurring and very meaningful for a lot of people. We
              hope to inspire right action, that you will take your Earth gazing
              thoughts and feelings and learn from them, share them, and be
              motivated to align yourself with the vision you know is possible
              for our future.
            </p>
            <p>
              Global View Foundation outreach programs, together called The
              EarthSeeds Project, center around distributing Planet Earth image
              prints, Earth Flags and photorealistic Earth globes that
              facilitate interpersonal activities for fun and learning.{" "}
              <Link className="text-lime-green" to="/planet-in-every-classroom">
                Explore
              </Link>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default earthSeedsPage
